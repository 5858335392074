import logo from './logo.svg';
import './App.css';
import { CardSwiper } from "react-card-rotate-swiper";

import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import data from "./data.json";
import { useState } from 'react';

const MAXSIZE = 10021;

function App() {

  const three_random_cards = () => {
    const nums = new Set();
    while (nums.size !== 3) {
      nums.add(Math.floor(Math.random() * MAXSIZE - 1) + 1);
    }
    return [...nums];
  }


  const [like, setLike] = useState("swipe right to like, and left to pass");
  const [cards, setCards] = useState([0])
  const [seen, setSeen] = useState([0])
  const [done, setDone] = useState(false)

  const [likedHistory, setLikedHistory] = useState([])





  const get_unseen_next_cards = (liked_id) => {
    let next_cards = data[liked_id].next_ids
    return next_cards.filter(n => !seen.includes(n))
  }


  const handleSwipe = (d) => {
    //handleToggle(d)
    if (d === "none") {
      return;
    }
    let currentCard = cards[0]
    let next_ids = []
    if (d === "right" || d === "up") {
      setLikedHistory([
        ...likedHistory,
        currentCard
      ]);
      next_ids = data[currentCard].next_ids
      setLike("you liked it")
    } else {
      setLike("you didn't like it")
    }
    next_ids = next_ids.filter(n => !seen.includes(n))
    if (next_ids.length == 0 && cards.length == 1 && seen.length != MAXSIZE) {
      let possible_next = currentCard
      while (seen.includes(possible_next)) {
        // Generate a random integer between 0 and MAXSIZE (inclusive)
        possible_next = Math.floor(Math.random() * MAXSIZE);
      }
      next_ids = [possible_next]
    }

    let final_ids = [...cards.slice(1,), ...next_ids]
    let final_seen = [...seen, ...next_ids]

    console.log("final_ids", final_ids)
    console.log("final_seen", final_seen)

    if (final_ids.length == 0) {
      setLike("that's all the products we have!")
      setDone(true)
    } else {
      setDone(false)
    }
    setCards(final_ids)
    setSeen(final_seen)
  };

  const handleClick = (index) => () => {
    console.log("clicked", index)
    const win = window.open(data[index].link, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  const CardList = ({ likedHistory, cards, handleSwipe }) => {
    return (
      <div>

        {likedHistory.map(id => (
          <div key={id}>
            <Card sx={{}}>
              <CardMedia
                sx={{ height: 300 }}
                image={`${data[id].image}`}
                title="green iguana"
              />
              {/* <img className="media-object media-thumbnail" src={`${data[id].image}`} alt="..." /> */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {data[id].title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {data[id].desc}
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={handleClick(id)}  size="small">Open Link</Button>
              </CardActions>
            </Card>
            <br />
          </div>
        ))}
      </div>
    );
  };

  const CardStack = ({ cards, handleSwipe }) => {
    return (
      <div>

        <CardSwiper
          onSwipe={handleSwipe}
          className={"swiper"}
          contents={
            //fill this your element
            <Card sx={{}}>
              <CardMedia
                sx={{ height: 300 }}
                image={`${data[cards[0]].image}`}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {data[cards[0]].title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {data[cards[0]].desc}
                </Typography>
              </CardContent>
            </Card>
          }
        />
      </div>
    );
  };

  let cs
  if (done == false) {
    cs = <CardStack cards={cards} handleSwipe={handleSwipe} />
  } else {
    cs = <p></p>
  }

  return (
    <div className="App">

      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="sm">
          <h1>ShopNext</h1>
          <p>{like}</p>

          {cs}
          <br /><br /><br />
          <h2>Liked products</h2>
          <CardList cards={cards} handleSwipe={handleSwipe} likedHistory={likedHistory} />

        </Container>
      </React.Fragment>
    </div>

  );


}

export default App;
